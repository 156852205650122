<template>
  <div class="service">
    <header v-if="IsPC()">
      <img
        class="header-bg"
        src="https://prod.oss.netopstec.com/net-home-banner3.png"
        alt=""
      />
      <h4>服务概览·全域营销</h4>
      <li>
        <img
          src="https://prod.oss.netopstec.com/net-service-cat11.png"
          alt=""
        />
        <span>全方位</span>
      </li>
      <li>
        <img src="https://prod.oss.netopstec.com/net-service-cat2.png" alt="" />
        <span>全渠道</span>
      </li>
      <li>
        <img src="https://prod.oss.netopstec.com/net-service-cat3.png" alt="" />
        <span>全数据</span>
      </li>
      <li>
        <img src="https://prod.oss.netopstec.com/net-service-cat4.png" alt="" />
        <span>全链路</span>
      </li>
      <li>
        <img src="https://prod.oss.netopstec.com/net-service-cat5.png" alt="" />
        <span>多品类</span>
      </li>
      <div class="headerText">
        网营致力于打造行业领先的多品类、全方位、全渠道、全链路、全数据的电商全域营销管理服务公司，为品牌客户实现从0到1线上营销飞跃。
      </div>
    </header>
    <header v-else>
      <img
        class="header-bg"
        src="https://prod.oss.netopstec.com/net-home-banner-h5.png"
        alt=""
      />
      <h4>服务概览·全域营销</h4>
      <li>
        <img
          src="https://prod.oss.netopstec.com/net-service-cat11.png"
          alt=""
        />
        <span>全方位</span>
      </li>
      <li>
        <img src="https://prod.oss.netopstec.com/net-service-cat2.png" alt="" />
        <span>全渠道</span>
      </li>
      <li>
        <img src="https://prod.oss.netopstec.com/net-service-cat3.png" alt="" />
        <span>全数据</span>
      </li>
      <li>
        <img src="https://prod.oss.netopstec.com/net-service-cat4.png" alt="" />
        <span>全链路</span>
      </li>
      <li>
        <img src="https://prod.oss.netopstec.com/net-service-cat5.png" alt="" />
        <span>多品类</span>
      </li>
      <div class="headerText">
        网营致力于打造行业领先的多品类、全方位、全渠道、全链路、全数据的电商全域营销管理服务公司，为品牌客户实现线上营销飞跃。
      </div>
    </header>
    <div ref="category" v-if="IsPC()" class="category">
      <img style="width:100%;" src="@/assets/netService/011.jpg" alt="">
      <!-- <div class="left">
        <h5>多品类</h5>
        <div class="gang"></div>
        <p>
          五大核心类目 <br />
          深耕细分行业需求
        </p>
      </div>
      <div class="right">
        <img
          class="im1"
          src="https://prod.oss.netopstec.com/net-service-category-log1.png"
          alt=""
        />
        <img
          class="im2"
          src="https://prod.oss.netopstec.com/net-service-category-log2.png"
          alt=""
        />
        <img
          class="im3"
          src="https://prod.oss.netopstec.com/net-service-category-log3.png"
          alt=""
        />
        <img
          class="im4"
          src="https://prod.oss.netopstec.com/net-service-category-log4.png"
          alt=""
        />
        <img
          class="im5"
          src="https://prod.oss.netopstec.com/net-service-category-log5.png"
          alt=""
        />
        <img
          class="im6"
          src="https://prod.oss.netopstec.com/net-service-category-log6.png"
          alt=""
        />
        <img
          class="im7"
          src="https://prod.oss.netopstec.com/net-service-category-log7.png"
          alt=""
        />
        <img
          class="im8"
          src="https://prod.oss.netopstec.com/net-service-category-log8.png"
          alt=""
        />
        <span class="span1">食品</span>
        <span class="span2">母婴</span>
        <span class="span3">服饰</span>
        <span class="span4">美妆</span>
        <span class="span5">宠物</span>
      </div> -->
    </div>
    <div ref="category" v-else class="category">
      <!-- <div class="left">
        <h5>多品类</h5>
        <div class="gang"></div>
        <p>五大核心类目 深耕细分行业需求</p>
      </div>
      <div class="right">
        <img
          class="im1"
          src="https://prod.oss.netopstec.com/net-service-category-log1.png"
          alt=""
        />
        <img
          class="im2"
          src="https://prod.oss.netopstec.com/net-service-category-log2.png"
          alt=""
        />
        <img
          class="im3"
          src="https://prod.oss.netopstec.com/net-service-category-log3.png"
          alt=""
        />
        <img
          class="im4"
          src="https://prod.oss.netopstec.com/net-service-category-log4.png"
          alt=""
        />
        <img
          class="im5"
          src="https://prod.oss.netopstec.com/net-service-category-log5.png"
          alt=""
        />
        <img
          class="im6"
          src="https://prod.oss.netopstec.com/net-service-category-log6.png"
          alt=""
        />
        <img
          class="im7"
          src="https://prod.oss.netopstec.com/net-service-category-log7.png"
          alt=""
        />
        <img
          class="im8"
          src="https://prod.oss.netopstec.com/net-service-category-log8.png"
          alt=""
        />
        <span class="span1">食品</span>
        <span class="span2">母婴</span>
        <span class="span3">服饰</span>
        <span class="span4">美妆</span>
        <span class="span5">宠物</span>
      </div> -->
      <img style="width:100%;" src="@/assets/netService/mobile/01.jpg" alt="">
    </div>
    <div ref="comprehensive" v-if="IsPC()" class="comprehensive">
      <!-- <div class="left">
        <li>
          <div>
            <span
              >TP、京东 <br />
              唯品会服务</span
            >
            <span>DP服务</span>
          </div>
          <img src="https://prod.oss.netopstec.com/lg1.png" alt="" />
          <i></i>
        </li>
        <li>
          <div>
            <span>整合营销 </span>
            <span>超级投手</span>
            <span>媒体推广</span>
            <span>数据银行 </span>
          </div>
          <img src="https://prod.oss.netopstec.com/lg2.png" alt="" />
          <i></i>
        </li>
        <li>
          <div>
            <span>IT解决方案 </span>
            <span>仓储物流</span>
            <span>视觉设计</span>
            <span>客户服务</span>
          </div>
          <img src="https://prod.oss.netopstec.com/lg3.png" alt="" />
          <i></i>
        </li>
        <li>
          <div>
            <span>品牌 <br />合作孵化</span>
            <span
              >新兴品牌 <br />
              助跑</span
            >
          </div>
          <img src="https://prod.oss.netopstec.com/lg4.png" alt="" />
          <i></i>
        </li>
      </div>
      <div class="right">
        <h5>全方位</h5>
        <div class="gang"></div>
        <p>
          四大服务板块 <br />

          赋能品牌营销
        </p>
      </div> -->
      <img style="width:100%;" src="@/assets/netService/012.png" alt="">
    </div>
    <div ref="comprehensive" v-else class="comprehensive">
      <!-- <div class="left">
        <li>
          <div>
            <span
              >TP、京东 <br />
              唯品会服务</span
            >
            <span>DP服务</span>
          </div>
          <img src="https://prod.oss.netopstec.com/lg1.png" alt="" />
          <i></i>
        </li>
        <li>
          <div>
            <span>整合营销 </span>
            <span>超级投手</span>
            <span>媒体推广</span>
            <span>数据银行 </span>
          </div>
          <img src="https://prod.oss.netopstec.com/lg2.png" alt="" />
          <i></i>
        </li>
        <li>
          <div>
            <span>IT解决方案 </span>
            <span>仓储物流</span>
            <span>视觉设计</span>
            <span>客户服务</span>
          </div>
          <img src="https://prod.oss.netopstec.com/lg3.png" alt="" />
          <i></i>
        </li>
        <li>
          <div>
            <span>品牌 <br />合作孵化</span>
            <span
              >新兴品牌 <br />
              助跑</span
            >
          </div>
          <img src="https://prod.oss.netopstec.com/lg4.png" alt="" />
          <i></i>
        </li>
      </div>
      <div class="right">
        <h5>全方位</h5>
        <div class="gang"></div>
        <p>四大服务板块 赋能品牌营销</p>
      </div> -->
       <img style="width:100%;" src="@/assets/netService/mobile/02.jpg" alt="">
    </div>
    <div ref="link" v-if="IsPC()" class="link">
      <!-- <div class="left">
        <h5>全链路</h5>
        <div class="gang"></div>
        <p>
          以数据为基础<br />
          贯穿品牌成长
        </p>
      </div>
      <div class="right">
        <img src="https://prod.oss.netopstec.com/net-service-link.png" alt="" />
      </div> -->
      <img style="width: 100%;" src="@/assets/netService/013.jpg" alt="">
    </div>
    <div ref="link" v-else class="link">
      <!-- <div class="left">
        <h5>全链路</h5>
        <div class="gang"></div>
        <p>以数据为基础 贯穿品牌成长</p>
      </div>
      <div class="right">
        <img src="https://prod.oss.netopstec.com/net-service-link-h5.png" alt="" />
      </div> -->
       <img style="width:100%;" src="@/assets/netService/mobile/03.jpg" alt="">
    </div>
    <div ref="channels" v-if="IsPC()" class="channels">
      <!-- <div class="left">
        <li>
          <img src="https://prod.oss.netopstec.com/LOG1.png" alt="" />
          <span>主流电商平台</span>
        </li>
        <li>
          <img src="https://prod.oss.netopstec.com/LOG2.png" alt="" />
          <span>种草/营销平台</span>
        </li>
        <li>
          <img src="https://prod.oss.netopstec.com/LOG3.png" alt="" />
          <span>社交电商</span>
        </li>
      </div>
      <div class="right">
        <h5>全渠道</h5>
        <div class="gang"></div>
        <p>
          布局多维场景 <br />
          制造营销触点
        </p>
      </div> -->
      <img style="width: 100%;" src="@/assets/netService/014.png" alt="">
    </div>
    <div ref="channels" v-else class="channels">
      <!-- <div class="left">
        <li>
          <img src="https://prod.oss.netopstec.com/LOG1.png" alt="" />
          <span>主流电商平台</span>
        </li>
        <li>
          <img src="https://prod.oss.netopstec.com/LOG2.png" alt="" />
          <span>种草/营销平台</span>
        </li>
        <li>
          <img src="https://prod.oss.netopstec.com/LOG3.png" alt="" />
          <span>社交电商</span>
        </li>
      </div>
      <div class="right">
        <h5>全渠道</h5>
        <div class="gang"></div>
        <p>布局多维场景 制造营销触点</p>
      </div> -->
       <img style="width:100%;" src="@/assets/netService/mobile/04.png" alt="">
    </div>
    <div ref="alldata" v-if="IsPC()" class="alldata">
      <!-- <div class="left">
        <h5>全数据</h5>
        <div class="gang"></div>
        <p>
          打通“人-货-场” <br />
          数据赋能品牌变现
        </p>
      </div>
      <div class="right">
        <img src="https://prod.oss.netopstec.com/net-serve-data.png" alt="" />
      </div> -->
      <img style="width: 100%;" src="@/assets/netService/015.png" alt="">
    </div>
    <div ref="alldata" v-else class="alldata">
      <!-- <div class="left">
        <h5>全数据</h5>
        <div class="gang"></div>
        <p>打通“人-货-场” 数据赋能品牌变现</p>
      </div>
      <div class="right">
        <img src="https://prod.oss.netopstec.com/net-serve-data.png" alt="" />
      </div> -->
       <img style="width:100%;" src="@/assets/netService/mobile/05.jpg" alt="">
    </div>
    <div ref="warehouse" v-if="IsPC()" class="warehouse">
      <!-- <div class="left">
        <img src="https://prod.oss.netopstec.com/net-serve-map4.png" alt="" />
      </div>
      <div class="right">
        <h5>仓储物流</h5>
        <div class="gang"></div>
        <p>打造更快、更好、更有保障的消费物流体验</p>
      </div> -->
      <img style="width: 100%;" src="@/assets/netService/016.png" alt="">
    </div>
    <div ref="warehouse" v-else class="warehouse">
      <!-- <div class="left">
        <img src="https://prod.oss.netopstec.com/net-serve-map4.png" alt="" />
      </div>
      <div class="right">
        <h5>仓储物流</h5>
        <div class="gang"></div>
        <p>打造更快、更好、更有保障的消费物流体验</p>
      </div> -->
       <img style="width:100%;" src="@/assets/netService/mobile/06.jpg" alt="">
    </div>
    <div class="footer" v-if="!IsPc">
        <p>版权所有:杭州网营科技股份有限公司</p>
        <p>
            Copyright@2015 Netopstec.All Rights Reserved <a href="https://www.beian.gov.cn/portal/registerSystemInfo"><img src="https://prod.oss.netopstec.com/gongan.png" alt="" style="display: inline-block;
          "/></a> 浙公网安备33018302001367号
            <a href="https://beian.miit.gov.cn/">浙ICP备12046102号</a>
        </p>
    </div>
    <div class="footer" v-else>
        <p>版权所有:杭州网营科技股份有限公司mobile</p>
        <p>
            Copyright@2015 Netopstec.All Rights Reserved

        </p>
        <a href="https://www.beian.gov.cn/portal/registerSystemInfo"><img src="https://prod.oss.netopstec.com/gongan.png" alt="" style="display: inline-block"/></a> 浙公网安备33018302001367号
        <a href="https://beian.miit.gov.cn/">浙ICP备12046102号</a>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    IsPC() {
      var userAgentInfo = navigator.userAgent;
      var Agents = [
        "Android",
        "iPhone",
        "SymbianOS",
        "Windows Phone",
        "iPad",
        "iPod",
      ];
      var flag = true;
      for (var v = 0; v < Agents.length; v++) {
        if (userAgentInfo.indexOf(Agents[v]) > 0) {
          flag = false;
          break;
        }
      }
      return flag;
    },
    handleScroll(e) {
      //滚动事件
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;

      if (
        scrollTop >=
        this.$refs.category.offsetTop - this.$refs.category.offsetHeight * 1
      ) {
        this.$refs.category.className = "category ani_ini";
      }
      if (
        scrollTop >=
        this.$refs.comprehensive.offsetTop -
          this.$refs.comprehensive.offsetHeight * 1
      ) {
        this.$refs.comprehensive.className = "comprehensive ani_ini";
      }
      if (
        scrollTop >=
        this.$refs.link.offsetTop - this.$refs.link.offsetHeight * 1
      ) {
        this.$refs.link.className = "link ani_ini";
      }
      if (
        scrollTop >=
        this.$refs.channels.offsetTop - this.$refs.channels.offsetHeight * 1
      ) {
        this.$refs.channels.className = "channels ani_ini";
      }
      if (
        scrollTop >=
        this.$refs.alldata.offsetTop - this.$refs.alldata.offsetHeight * 1
      ) {
        this.$refs.alldata.className = "alldata ani_ini";
      }
      if (
        scrollTop >=
        this.$refs.warehouse.offsetTop - this.$refs.warehouse.offsetHeight * 1
      ) {
        this.$refs.warehouse.className = "warehouse ani_ini";
      }
    },
  },
};
</script>

<style lang="less" scoped>
@media screen and (min-width: 1100px) {
  header {
    position: relative;
    .header-bg {
      width: 100%;
      position: relative;
      z-index: -1;
    }
    h4 {
      position: absolute;
      left: 686px;
      top: 323px;
      font-size: 70px;
      letter-spacing: 5px;
      color: #ffffff;
      line-height: 69px;
      text-shadow: 0 0 2px #e5dede;
      opacity: 0;
      animation: bannerLogo 1.5s linear 1;
      animation-fill-mode: forwards;
    }
    li {
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: center;
      opacity: 0;
      animation: bannerLogo 1.5s linear 1;
      animation-fill-mode: forwards;
      span {
        margin-top: 10px;
        font-size: 24px;
        font-weight: 500;
        color: #ffffff;
        line-height: 35px;
      }
      img {
        width: 120px;
        // height: 89px;
      }
    }
    & > li:nth-child(3) {
      top: 298px;
      left: 362px;
    }
    & > li:nth-child(4) {
      top: 498px;
      left: 524px;
    }
    & > li:nth-child(5) {
      top: 547px;
      left: 884px;
    }
    & > li:nth-child(6) {
      top: 517px;
      left: 1247px;
    }
    & > li:nth-child(7) {
      top: 292px;
      left: 1453px;
    }
    .headerText {
      position: absolute;
      width: 75%;
      bottom: 50px;
      transform: translateX(-50%);
      left: 50%;
      font-size: 30px;
      line-height: 60px;
      text-align: center;
      color: #fff;
      background: rgba(0, 0, 0, 0.3);
      padding: 10px 50px;
    }
  }
  .category {
    position: relative;
    background: url("https://prod.oss.netopstec.com/net-service-category.png")
      no-repeat top center;
    background-size: 100% 100%;
    height: 557px;
    .left {
      opacity: 0;
      position: absolute;
      top: 189px;
      left: 215px;
      h5 {
        font-weight: 400;
        font-size: 35px;
        color: #666666;
      }
      .gang {
        margin-top: 40px;
        width: 256px;
        height: 1px;
        background: #750c21;
        border: 1px solid #666666;
      }
      p {
        font-size: 25px;
        color: #666666;
        line-height: 50px;
      }
    }
    .right {
      opacity: 0;
      position: relative;
      img {
        position: absolute;
      }
      .im1 {
        left: 956px;
        top: 226px;
        width: 96px;
        height: 96px;
      }
      .im2 {
        left: 1103px;
        top: 226px;
        width: 96px;
        height: 96px;
      }
      .im3 {
        left: 1252px;
        top: 226px;
        width: 96px;
        height: 96px;
      }
      .im4 {
        left: 1399px;
        top: 226px;
        width: 96px;
        height: 96px;
      }
      .im5 {
        left: 1548px;
        top: 226px;
        width: 96px;
        height: 96px;
      }
      .im6 {
        left: 1001px;
        top: 161px;
        width: 37px;
        height: 37px;
      }
      .im7 {
        left: 1638px;
        top: 214px;
        width: 33px;
        height: 33px;
      }
      .im8 {
        left: 1218px;
        top: 394px;
        width: 67px;
        height: 67px;
      }
      span {
        position: absolute;
        font-size: 25px;
        color: #a0112d;
        line-height: 25px;
      }
      .span1 {
        left: 979px;
        top: 336px;
      }
      .span2 {
        left: 1127px;
        top: 336px;
      }
      .span3 {
        left: 1275px;
        top: 336px;
      }
      .span4 {
        left: 1422px;
        top: 336px;
      }
      .span5 {
        left: 1571px;
        top: 336px;
      }
    }
  }
  .comprehensive {
    position: relative;
    background: url("https://prod.oss.netopstec.com/net-service-comprehensive.png")
      no-repeat top center;
    background-size: 100% 100%;
    height: 558px;
    .left {
      opacity: 0;
      position: absolute;
      left: 323px;
      top: 135px;
      display: flex;
      li {
        list-style: none;
        display: flex;
        flex-direction: column;
        margin-right: 50px;
        align-items: center;
        div {
          width: 119px;
          height: 216px;
          background: linear-gradient(0deg, #750d22 0%, #85293b 100%);
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
          justify-content: space-around;
          span {
            font-size: 16px;
            font-weight: 300;
            color: #ffffff;
            line-height: 30px;
          }
        }
        img {
          width: 47px;
          height: 47px;
        }
        i {
          position: relative;
          top: -20px;
          width: 123px;
          height: 42px;
          background: rgba(255, 255, 255, 0.1);
          border-radius: 50%;
        }
      }
    }
    .right {
      opacity: 0;
      position: absolute;
      top: 189px;
      right: 408px;
      h5 {
        font-weight: 400;
        font-size: 35px;
        color: #f9f9f9;
      }
      .gang {
        margin-top: 40px;
        width: 256px;
        height: 1px;
        background: #f9f9f9;
        border: 1px solid #f9f9f9;
      }
      p {
        font-size: 25px;
        color: #f9f9f9;
        line-height: 50px;
      }
    }
  }
  .link {
    position: relative;
    background: url("https://prod.oss.netopstec.com/net-service-category.png")
      no-repeat top center;
    background-size: 100% 100%;
    height: 557px;
    .left {
      opacity: 0;
      position: absolute;
      top: 189px;
      left: 215px;
      h5 {
        font-weight: 400;
        font-size: 35px;
        color: #666666;
      }
      .gang {
        margin-top: 40px;
        width: 256px;
        height: 1px;
        background: #750c21;
        border: 1px solid #666666;
      }
      p {
        font-size: 25px;
        color: #666666;
        line-height: 50px;
      }
    }
    .right {
      opacity: 0;
      img {
        position: absolute;
        left: 701px;
        top: 163px;
        width: 995px;
        height: 248px;
      }
    }
  }
  .channels {
    position: relative;
    background: url("https://prod.oss.netopstec.com/net-service-channels.png")
      no-repeat top center;
    background-size: 100% 100%;
    // height: 559px;
    .left {
      opacity: 0;
      position: absolute;
      top: 191px;
      left: 291px;
      display: flex;
      li {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 50px;
        img {
          width: 120px;
          height: 120px;
        }
        span {
          margin-top: 25px;
          font-size: 22px;
          font-weight: 500;
          color: #ffffff;
          line-height: 30px;
        }
      }
    }
    .right {
      opacity: 0;
      position: absolute;
      top: 189px;
      right: 408px;
      h5 {
        font-weight: 400;
        font-size: 35px;
        color: #f9f9f9;
      }
      .gang {
        margin-top: 40px;
        width: 256px;
        height: 1px;
        background: #f9f9f9;
        border: 1px solid #f9f9f9;
      }
      p {
        font-size: 25px;
        color: #f9f9f9;
        line-height: 50px;
      }
    }
  }
  .alldata {
    position: relative;
    background: url("https://prod.oss.netopstec.com/net-service-category.png")
      no-repeat top center;
    background-size: 100% 100%;
    height: 557px;
    .left {
      opacity: 0;
      position: absolute;
      top: 189px;
      left: 215px;
      h5 {
        font-weight: 400;
        font-size: 35px;
        color: #666666;
      }
      .gang {
        margin-top: 40px;
        width: 256px;
        height: 1px;
        background: #750c21;
        border: 1px solid #666666;
      }
      p {
        font-size: 25px;
        color: #666666;
        line-height: 50px;
      }
    }
    .right {
      opacity: 0;
      img {
        position: absolute;
        right: 401px;
        top: 100px;
        width: 301px;
        height: 301px;
      }
    }
  }
  .warehouse {
    position: relative;
    background: url("https://prod.oss.netopstec.com/net-service-warehouse2.png")
      no-repeat top center;
    background-size: 100% 100%;
    height: 559px;
    display: flex;
    .left {
      opacity: 0;
      img {
        position: absolute;
        top: 116px;
        left: 347px;
        width: 400px;
        height: 339px;
      }
      p {
        width: 300px;
        position: absolute;
        top: 458px;
        left: 501px;
        font-size: 22px;
        font-weight: 400;
        color: #f9f9f9;
        line-height: 31px;
      }
    }
    .right {
      opacity: 0;
      position: absolute;
      top: 189px;
      right: 408px;
      h5 {
        font-weight: 400;
        font-size: 35px;
        color: #f9f9f9;
      }
      .gang {
        margin-top: 40px;
        width: 300px;
        height: 1px;
        background: #f9f9f9;
        border: 1px solid #f9f9f9;
      }
      p {
        width: 300px;
        font-size: 25px;
        color: #f9f9f9;
        line-height: 50px;
      }
    }
  }
}
@media screen and (max-width: 1100px) {
  header {
    position: relative;
    .header-bg {
      width: 100%;
      position: relative;
      z-index: -1;
    }
    h4 {
      position: absolute;
      left: 76px;
      top: 289px;
      font-size: 70px;
      letter-spacing: 5px;
      color: #ffffff;
      line-height: 69px;
      text-shadow: 0 0 2px #e5dede;
      opacity: 0;
      animation: bannerLogo 1.5s linear 1;
      animation-fill-mode: forwards;
    }
    li {
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: center;
      opacity: 0;
      animation: bannerLogo 1.5s linear 1;
      animation-fill-mode: forwards;
      span {
        margin-top: 10px;
        font-size: 24px;
        font-weight: 300;
        color: #ffffff;
        line-height: 35px;
      }
      img {
        width: 100px;
      }
    }
    & > li:nth-child(3) {
      top: 450px;
      left: 49px;
    }
    & > li:nth-child(4) {
      top: 548px;
      left: 188px;
    }
    & > li:nth-child(5) {
      top: 450px;
      left: 330px;
    }
    & > li:nth-child(6) {
      top: 548px;
      left: 467px;
    }
    & > li:nth-child(7) {
      top: 450px;
      left: 604px;
    }
    .headerText {
      position: absolute;
      width: 75%;
      bottom: 230px;
      transform: translateX(-50%);
      left: 50%;
      font-size: 22px;
      line-height: 60px;
      text-align: center;
      color: #fff;
      background: rgba(0, 0, 0, 0.3);
      padding: 20px 40px;
    }
  }
  .category {
    position: relative;
    // height: 596px;
    .left {
      opacity: 0;
      position: absolute;
      top: 160px;
      width: 750px;
      h5 {
        text-align: center;
        font-weight: 400;
        font-size: 35px;
        color: #000;
      }
      .gang {
        width: 69px;
        height: 5px;
        margin: 31px auto 0;
        background: #0f1212;
      }
      p {
        margin-top: 32px;
        text-align: center;
        font-size: 25px;
        color: #000;
        line-height: 44px;
      }
    }
    .right {
      opacity: 0;
      position: relative;
      img {
        position: absolute;
      }
      .im1 {
        left: 167px;
        top: 348px;
        width: 60px;
        height: 60px;
      }
      .im2 {
        left: 256px;
        top: 348px;
        width: 60px;
        height: 60px;
      }
      .im3 {
        left: 346px;
        top: 348px;
        width: 60px;
        height: 60px;
      }
      .im4 {
        left: 436px;
        top: 348px;
        width: 60px;
        height: 60px;
      }
      .im5 {
        left: 526px;
        top: 348px;
        width: 60px;
        height: 60px;
      }
      .im6 {
        left: 49px;
        top: 390px;
        width: 41px;
        height: 41px;
      }
      .im7 {
        right: 50px;
        top: 328px;
        width: 20px;
        height: 20px;
      }
      .im8 {
        right: 8px;
        top: 399px;
        width: 23px;
        height: 23px;
      }
      span {
        position: absolute;
        font-size: 25px;
        width: 60px;
        text-align: center;
        color: #a0112d;
        line-height: 25px;
      }
      .span1 {
        left: 167px;
        top: 417px;
      }
      .span2 {
        left: 256px;
        top: 417px;
      }
      .span3 {
        left: 346px;
        top: 417px;
      }
      .span4 {
        left: 436px;
        top: 417px;
      }
      .span5 {
        left: 526px;
        top: 417px;
      }
    }
  }
  .comprehensive {
    position: relative;
    background: url("https://prod.oss.netopstec.com/net-service-comprehensive-h5.png")
      no-repeat top center;
    background-size: 100% 100%;
    height: 823px;
    .left {
      width: 750px;
      justify-content: center;
      opacity: 0;
      position: absolute;
      left: 0;
      top: 349px;
      display: flex;
      li {
        list-style: none;
        display: flex;
        flex-direction: column;
        margin-right: 20px;
        align-items: center;
        div {
          width: 119px;
          height: 216px;
          background: linear-gradient(0deg, #750d22 0%, #85293b 100%);
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
          justify-content: space-around;
          span {
            font-size: 16px;
            font-weight: 300;
            color: #ffffff;
            line-height: 30px;
          }
        }
        img {
          width: 47px;
          height: 47px;
        }
        i {
          position: relative;
          top: -20px;
          width: 123px;
          height: 42px;
          background: rgba(255, 255, 255, 0.1);
          border-radius: 50%;
        }
      }
    }
    .right {
      opacity: 0;
      position: absolute;
      top: 160px;
      width: 750px;
      h5 {
        text-align: center;
        font-weight: 400;
        font-size: 35px;
        color: #fff;
      }
      .gang {
        width: 69px;
        height: 5px;
        margin: 31px auto 0;
        background: #fff;
      }
      p {
        margin-top: 32px;
        text-align: center;
        font-size: 25px;
        color: #fff;
        line-height: 44px;
      }
    }
  }
  .link {
    position: relative;
    // height: 739px;
    .left {
      opacity: 0;
      position: absolute;
      top: 100px;
      width: 750px;
      h5 {
        text-align: center;
        font-weight: 400;
        font-size: 35px;
        color: #000;
      }
      .gang {
        width: 69px;
        height: 5px;
        margin: 31px auto 0;
        background: #0f1212;
      }
      p {
        margin-top: 32px;
        text-align: center;
        font-size: 25px;
        color: #000;
        line-height: 44px;
      }
    }
    .right {
      opacity: 0;
      img {
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        top: 280px;
        width: 652px;
        height: 238px;
      }
    }
  }
  .channels {
    position: relative;
    background: url("https://prod.oss.netopstec.com/net-service-channels-h5.png")
      no-repeat top center;
    background-size: 100% 100%;
    // height: 641px;
    .left {
      opacity: 0;
      position: absolute;
      left: 0;
      right: 0;
      width: 750px;
      top: 350px;
      // margin: 0 auto;
      justify-content: center;
      display: flex;
      li {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 50px;
        img {
          width: 120px;
          height: 120px;
        }
        span {
          margin-top: 25px;
          font-size: 22px;
          font-weight: 500;
          color: #ffffff;
          line-height: 30px;
        }
      }
    }
    .right {
      opacity: 0;
      position: absolute;
      top: 160px;
      width: 750px;
      h5 {
        text-align: center;
        font-weight: 400;
        font-size: 35px;
        color: #fff;
      }
      .gang {
        width: 69px;
        height: 5px;
        margin: 31px auto 0;
        background: #fff;
      }
      p {
        margin-top: 32px;
        text-align: center;
        font-size: 25px;
        color: #fff;
        line-height: 44px;
      }
    }
  }
  .alldata {
    position: relative;
    height: 857px;
    .left {
      opacity: 0;
      position: absolute;
      top: 160px;
      width: 750px;
      h5 {
        text-align: center;
        font-weight: 400;
        font-size: 35px;
        color: #000;
      }
      .gang {
        width: 69px;
        height: 5px;
        margin: 31px auto 0;
        background: #0f1212;
      }
      p {
        margin-top: 32px;
        text-align: center;
        font-size: 25px;
        color: #000;
        line-height: 44px;
      }
    }
    .right {
      opacity: 0;
      img {
        position: absolute;
        right: 0;
        left: 0;
        margin: 0 auto;
        top: 349px;
        width: 301px;
        height: 301px;
      }
    }
  }
  .warehouse {
    position: relative;
    background: url("https://prod.oss.netopstec.com/net-service-warehouse2.png")
      no-repeat top center;
    background-size: 100% 100%;
    height: 748px;
    display: flex;
    .left {
      width: 750px;
      display: flex;
      justify-content: center;
      opacity: 0;
      img {
        position: absolute;
        top: 349px;

        width: 279px;
        height: 238px;
      }
    }
    .right {
      opacity: 0;
      position: absolute;
      top: 160px;
      width: 750px;
      h5 {
        text-align: center;
        font-weight: 400;
        font-size: 35px;
        color: #fff;
      }
      .gang {
        width: 69px;
        height: 5px;
        margin: 31px auto 0;
        background: #fff;
      }
      p {
        margin-top: 32px;
        text-align: center;
        font-size: 25px;
        color: #fff;
        line-height: 44px;
      }
    }
  }
}

.footer {
  // width: 750px;
  height: 216px;
  background: url("https://prod.oss.netopstec.com/net-about-foot-8-9.png") no-repeat
    top center;
    background-size: 100% 100%;
  padding-top: 100px;
  text-align: center;
  font-size: 20px;
  font-weight: 400;
  color: #ffffff;
  line-height: 35px;
  p {
    width: 100%;
  }
  a {
    color: #ffffff;
    text-decoration: none;
  }
}
.ani_ini {
  .left {
    animation: ani_ini 1s linear 1;
    animation-fill-mode: forwards;
  }
  .right {
    animation: ani_ini 1s linear 1;
    animation-fill-mode: forwards;
  }
}
@keyframes bannerLogo {
  0% {
    transform: translateY(30px);
    opacity: 0;
  }
  50% {
    transform: translateY(30px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes ani_ini {
  0% {
    transform: translateY(50px);
    opacity: 0;
  }
  50% {
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
</style>